import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import Container from "components/Container";

const ResumePage = () => {
  return (
    <Layout pageName="resume">
      <Helmet>
        <title>Jesus Vera - Resume</title>
      </Helmet>
      <Container>
        <h1>Resume</h1>
      </Container>
    </Layout>
  );
};

export default ResumePage;
